import classNames from "classnames";
import {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";

import Icon from "~/components/common/icon";

import styles from "./checkbox.module.scss";

export enum CheckboxVariants {
  default = "default",
  toggle = "toggle",
  visibility = "visibility",
}

type CustomStyles = {
  container?: string;
  containerWithErrors?: string;
  inputCheckbox?: string;
  icon?: string;
  labelCheckbox?: string;
  errorMessage?: string;
};

type CheckboxIconProps = {
  checked: boolean;
  variant: CheckboxProps["variant"];
  className?: string;
  width?: string | number;
  height?: string | number;
};

export function CheckboxIcon({ checked, variant, className, width, height }: CheckboxIconProps) {
  const iconNames = useMemo(() => {
    switch (variant) {
      case CheckboxVariants.toggle:
        return { checked: "toggle-on", unchecked: "toggle-off" } as const;
      case CheckboxVariants.visibility:
        return { checked: "show", unchecked: "hide" } as const;
      case CheckboxVariants.default:
      default:
        return { checked: "checkbox-selected", unchecked: "checkbox-unselected" } as const;
    }
  }, [variant]);

  return (
    <Icon
      name={checked ? iconNames.checked : iconNames.unchecked}
      // Remove the `fill` property from the hide icon
      style={{ fill: !checked && variant === "visibility" ? "none" : undefined }}
      className={classNames(styles.icon, className)}
      width={width}
      height={height}
    />
  );
}

type CheckboxProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  variant?: CheckboxVariants;
  label:
    | string
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactNode
    | ReactNode[]
    | undefined
    | number
    | boolean;
  error?: string;
  customStyles?: CustomStyles;
};

type CheckboxPropsNoValue = Omit<CheckboxProps, "value">;

export default forwardRef<HTMLInputElement, CheckboxPropsNoValue>(function Checkbox(
  { variant = CheckboxVariants.default, label, error, customStyles, className, width = 20, height = 20, ...rest },
  ref
) {
  const id = useId();
  const [checked, setChecked] = useState(rest.defaultChecked ?? false);

  // effect for sync external controlled state with internal
  useEffect(() => {
    if (rest.checked != undefined) {
      setChecked(rest.checked);
    } else if (rest.defaultChecked == undefined) {
      setChecked(false);
    }
  }, [rest.checked, rest.defaultChecked]);

  return (
    <div
      className={classNames(
        styles.container,
        className,
        customStyles?.container,
        error ? styles.containerWithErrors : null,
        error ? customStyles?.containerWithErrors : null,
        rest.disabled ? styles.disabled : null
      )}
    >
      <input
        {...rest}
        id={id}
        ref={ref}
        type="checkbox"
        className={classNames(styles.inputCheckbox, customStyles?.inputCheckbox)}
        onChange={(e) => {
          setChecked(e.target.checked);
          rest.onChange?.(e);
        }}
        aria-invalid={error ? "true" : "false"}
        aria-errormessage={`${id}-error`}
      />
      <CheckboxIcon checked={checked} variant={variant} className={customStyles?.icon} width={width} height={height} />
      {label ? (
        <label
          htmlFor={id}
          onMouseDown={(e) => e.preventDefault()}
          className={classNames(styles.labelCheckbox, customStyles?.labelCheckbox)}
        >
          {label}
        </label>
      ) : null}
      {error && (
        <p id={`${id}-error`} className={classNames(styles.errorMessage, customStyles?.errorMessage)}>
          {error}
        </p>
      )}
    </div>
  );
});
